html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.homeswiper {
  width: 100%;
  height: 100%;
  font-family:"Big Shoulders Text";
}

.homeslide {
  overflow-y:auto;
  overflow-x:hidden;
}