.evidenceswiper {
  width: 100%;
  height: 100%;
  font-family: "Big Shoulders Text";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.evidenceslide{
    width: 100%;
    height: 100%;
    font-family: "Big Shoulders Text";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y:auto;
}
