html,
body {
  position: relative;
  height: 100%;
}

body {
  background-image:url("https://i.imgur.com/ip3r7A4.jpg");

  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swipermain {
  width: 100%;
  height: 92%;
  font-family:"Big Shoulders Text";
}
